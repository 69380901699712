import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "./post.scss"

const Post = ({ pageContext, data }) => {
  const post = pageContext.post
  return (
    <>
      <SEO title={post.title} />
      <Layout>
        <article id="post">
          <section className="image">
            <Img fluid={data.desktopImage.childImageSharp.fluid} />
            <div className="gradient"></div>
          </section>
          <header>
            <h1> {post.title} </h1>
          </header>
          <section>
            <div dangerouslySetInnerHTML={{ __html: post.content }} />
          </section>
        </article>
      </Layout>
    </>
  )
}

export default Post

export const query = graphql`
  query {
    desktopImage: file(name: { eq: "blog" }, extension: { eq: "jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
